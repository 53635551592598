import { defineStore } from 'pinia';
import { webSocket as ws } from 'rxjs/webSocket';
import { RetryConfig, retry, tap } from 'rxjs';

const WS_HOST = <string>useRuntimeConfig().public.STORE_WSS_URI || "wss://qxxiqzz38c.execute-api.eu-central-1.amazonaws.com/prod";
const HEART_BEAT_INTERVAL = 1000 * 60; // every minute
const RECONNECT_INTERVAL = 60 * 60 * 1000; // every hour

const defaultRoom = 'darkRoom';

let subject;
let heartbeat;
let reconnectInterval;

interface IObjectKeys {
	[key: string]: any;
}

export const usePanelStore = defineStore({
	id: "panelStore",
	state: (): IObjectKeys => {
		return {};
	},
	actions: {
		get(key: string) {
			return this.$state[key];
		},
		async set(key: string, value: any) {
			await this.setLocal(key, value);
			await this.setRemote(key, value);
		},
		setLocal(key: string, value: any) {
			try {
				this.$state[key] = value;
			} catch (error) {
				return Promise.reject(error)
			}
			return Promise.resolve(true)
		},
		async setRemote(key: string, value: any) {
			try {
				subject.next({
					action: 'updateSettings',
					payload: [
						{
							room: defaultRoom,
							key,
							value,
						},
					],
				});
			} catch (error) {
				return Promise.reject(error)
			}
			return Promise.resolve(true)
		},
		ping(subject: any) {

		},
		disconnect() {
			if (subject) {
				subject.complete(); // Close the WebSocket connection gracefully.
				clearInterval(heartbeat);
			}
		},
		init(space: any) {
			const createWebSocket = () => {
				//console.log(`\x1b[32m[${new Date().toLocaleTimeString()}] !!!!!!!!!!!!!!! RECONNECT TO WEBSOCKET !!!!!!!!!!!!!!!!!!\x1b[0m`);

				// Clean up the existing WebSocket connection if it exists.
				this.disconnect();

				subject = ws({
					url: WS_HOST,
				});

				const retryConfig: RetryConfig = {
					delay: 3000,
				};

				subject.pipe(retry(retryConfig)).subscribe();

				subject
					.asObservable()
					.pipe(
						tap((data: any) => {
							const { action } = data;
							if (!action) {
								return;
							}
							if (!data.payload || !data.payload.length) {
								return;
							}
							switch (action) {
								case 'INITIALISED':
									data.payload.map(async (p: any) => {
										const { key, value } = p;
										await this.setLocal(key, value);
										space.eventbus.emit(key, value)
									});
									break;
								case 'UPDATED':
									data.payload.map(async (p: any) => {
										var { key, value } = p;
										await this.setLocal(key, value);
										space.eventbus.emit(key, value)
									});
									break;
								case 'PONG':
									data.payload.map(async (p: any) => {
										var { date } = p;
										console.warn("PONG", date, p['date'])
									});
									break;
							}
						})
					)
					.subscribe();

				subject.next({ action: 'assignUser', roomName: defaultRoom });
				heartbeat = setInterval(() => subject.next({ action: 'ping' }), HEART_BEAT_INTERVAL);
			};

			createWebSocket(); // Initial WebSocket connection
			// Reconnect after a delay (e.g., every hour)
			reconnectInterval = setInterval(() => {
				createWebSocket();
			}, RECONNECT_INTERVAL);

			// Handle WebSocket connection status
			subject.subscribe((event) => {
				if (event.type === 'open') {
					console.log('WebSocket connection is open');
				} else if (event.type === 'close') {
					console.error('WebSocket connection is closed');
					createWebSocket();
				}
			});
		},
	},
});
