<template>
	<SpaceModal :show=showStageModal @closed="showStageModal = false">
		<div class="content">
			<h1>Stage</h1>
			<p v-if="!isPlaying && modalData.Description">{{ modalData.Description }}</p>
			<div class="wrap" v-if="!isPlaying">
				<div class="video" v-for="video in modalData.videos" @click="startVideo(video)">
					<div class="image" :style="'background-image: url(' + video.CoverImage.data.attributes.url + ');'"></div>
					<h3>{{ video.Title }}</h3>
					<p v-if="video.Description">{{ video.Description }}</p>
					<div class="btn">Starten</div>
				</div>
			</div>
			<div class="wrap" v-else>
				<div class="btn" @click="reset()">Stoppen</div>
			</div>

		</div>
	</SpaceModal>
</template>

<script setup lang="ts">
import { isAnyTextInputActive } from '@ravespaceio/rave-engine/build/engine/src/utils/browser';
import { storeToRefs } from 'pinia';
import { canUseActionKey } from '~/space/_lib/utils/frontend';
import { getSpace } from '~/space/space';
import { useSpaceStore } from '~/store/space';
import { dekaBotPositions, useGameStore } from '~/store/game';
import { getEngine } from '~/space/engine';
import HighlightCylinder from '@ravespaceio/rave-engine/build/engine/src/objects/other/HighlightCylinder';
import { SphereCollider } from '@ravespaceio/rave-engine/build/engine/src/helper/interaction/SimpleColliderSystem';
import { resetDekaBot } from '~/space/dek/game/dekabot';
import * as THREE from "three"
import { PlayerCharacter } from '@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerCharacter/PlayerCharacter';

const space = getSpace()
const engine = getEngine()
const spaceStore = useSpaceStore()
const gameStore = useGameStore()
const { showStageModal } = storeToRefs(spaceStore)

const apiurl = space.ENV.CMS_URI + "/api/stage-on-demand?populate=*,videos.CoverImage,videos.StageVideo,videos.ScreenVideo"
const modalData = ref()
const isPlaying = ref(false)

fetch(apiurl, {
	method: "GET",
}).then((response) => { return response.json(); }).then((data) => { continueSetup(data) }).catch(function (error) { console.log(error) });

function continueSetup(data) {
	modalData.value = data.data.attributes
	console.log(modalData.value)

}

function startVideo(data) {
	const space = getSpace();
	const stageVideoURL = data.StageVideo.data.attributes.url
	const screenVideoURL = data.ScreenVideo.data.attributes.url
	space.screenManager.updateScreenTexturebynewUrl(100, screenVideoURL);
	space.screenManager.updateScreenTexturebynewUrl(101, stageVideoURL);
	useSpaceStore().showStageModal = false
	isPlaying.value = true
}

function reset() {
	space.screenManager.updateScreenTexturebynewUrl(100, spaceStore.stagePlaceholder ? spaceStore.stagePlaceholder : "/dek/img/placeholder.jpg");
	space.screenManager.updateScreenTexturebynewUrl(101, "none");
	isPlaying.value = false
	spaceStore.showStageModal = false
}



const hc = new HighlightCylinder()
engine.scene.add(hc)
hc.scale.set(2, 2.5, 2)
hc.position.set(-7.86, 1, 33.8)

const isEvent = ref(false)
space.eventbus.onAlready("closeExperience", (data: any) => {
	if (data == true) {
		isEvent.value = true
		hc.visible = false
	} else {
		isEvent.value = false
		hc.visible = true
	}
})



space.pciSystem.add({
	collider: new SphereCollider(hc, 3),
	onColliderEnter: () => {
		if (isEvent.value == false) {
			spaceStore.hint.title = "Stage"
			engine.player.getInputController<PlayerCharacter>().activeActions.jumping = false; // avoid jumping
		}
	},
	onColliderLeave: () => {
		engine.player.getInputController<PlayerCharacter>().activeActions.jumping = true; // avoid jumping
		spaceStore.hint.title = undefined
	},
	onInteract: () => {
		if (isEvent.value == false) {
			spaceStore.showStageModal = true
			space.pciSystem.triggerNearestExit()
			spaceStore.hint.title = undefined
		}

	}
})


</script>

<style scoped lang="scss">
@import "assets/css/variables";

.content {
	@extend .flex-center;
	flex-direction: column;
	width: 100%;

	.wrap {
		margin: 20px 0;
		@extend .flex-center;
		flex-wrap: wrap;
		align-items: normal;

		.video {
			@extend .flex-center;
			flex-direction: column;
			justify-content: flex-start;
			text-align: center;
			background: $secondary;
			padding: 20px;
			border-radius: $radius;
			box-shadow: 0px 5px 10px #353b5017;
			width: 300px;
			cursor: pointer;

			height: auto;
			margin: 10px;

			h3 {
				font-weight: 700;
			}

			p {
				font-size: 14px;
				margin-bottom: 20px;
			}

			.btn {
				margin-top: auto;
				padding: 10px 20px;
				width: 100%;
			}

			.image {
				width: 100%;
				height: 100px;
				border-radius: $radius;
				background-size: cover;
				background-position: center;
				margin-bottom: 12px;
			}

		}
	}
}
</style>
