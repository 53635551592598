<template>
	<SpaceModal :show=show @closed="close()">
		<div class="content">
			<h1>Die
				<IconLogo /> bietet
			</h1>

			<div class="description">
				<h2>{{ title }}</h2>
				<p>{{ text }}</p>
			</div>
			<div class="wrapper">
				<img v-for="gif in opportunities" :src="gif.current ? gif.current : gif.static" v-on:mouseover="gif.current = gif.gif; title = gif.title; text = gif.text;" v-on:mouseleave="gif.current = undefined; title = '‎'; text = '‎';">
			</div>
		</div>
	</SpaceModal>
</template>

<script setup lang="ts">
import { getSpace } from '~/space/space';
import { useSpaceStore } from '~/store/space';
import { storeToRefs } from 'pinia';
import { useGameStore } from '~/store/game';
import { useToast } from 'vue-toastification'
import HighlightCylinder from '@ravespaceio/rave-engine/build/engine/src/objects/other/HighlightCylinder';
import { getEngine } from '~/space/engine';
import { SphereCollider } from '@ravespaceio/rave-engine/build/engine/src/helper/interaction/SimpleColliderSystem';
import { Static } from 'nuxt/dist/app/compat/capi';
import { PlayerCharacter } from '@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerCharacter/PlayerCharacter';
const space = getSpace()
const engine = getEngine()
const gameStore = useGameStore()
const spaceStore = useSpaceStore()
const toast = useToast()
const { showBenefitsModal } = storeToRefs(spaceStore)
const show = computed(() => !!showBenefitsModal.value)

const title = ref("‎")
const text = ref("‎")
const opportunities = ref([
	{
		gif: "/dek/img/entry/1.gif",
		static: "/dek/img/entry/1.jpg",
		current: undefined,
		title: "Attraktives Gehalt",
		text: "#NeverBrokeAgain #MoneyMaker"
	},
	{
		gif: "/dek/img/entry/2.gif",
		static: "/dek/img/entry/2.jpg",
		current: undefined,
		title: "Teilzeitangebote und berufliche Auszeiten",
		text: "#Sabbatical #SeeYou #MeTime"
	},
	{
		gif: "/dek/img/entry/3.gif",
		static: "/dek/img/entry/3.jpg",
		current: undefined,
		title: "30 Tage Urlaub/Jahr",
		text: "#VacationModeOn #OverAndOut"
	},
	{
		gif: "/dek/img/entry/4.gif",
		static: "/dek/img/entry/4.jpg",
		current: undefined,
		title: "Betriebliche Altersversorgung & vermögenswirksame Leistungen",
		text: "#PrimeTime #InvestingInMyself"
	},
	{
		gif: "/dek/img/entry/5.gif",
		static: "/dek/img/entry/5.jpg",
		current: undefined,
		title: "Mobiles Arbeiten im Ausland",
		text: "#Workation #LifeIsBetterAtTheBeach"
	},
	{
		gif: "/dek/img/entry/6.gif",
		static: "/dek/img/entry/6.jpg",
		current: undefined,
		title: "Kostenloser Kaffee und Tee",
		text: "#StayHydrated"
	},
	{
		gif: "/dek/img/entry/7.gif",
		static: "/dek/img/entry/7.jpg",
		current: undefined,
		title: "Einkaufsvergünstigungen",
		text: "#Sparfuchs #Shopaholic #Schnäppchenjäger "
	},
	{
		gif: "/dek/img/entry/8.gif",
		static: "/dek/img/entry/8.jpg",
		current: undefined,
		title: "Fahrradleasing",
		text: "#PimpMyBike #TheySeeMeRollin'TheyHatin'"
	},
	{
		gif: "/dek/img/entry/9.gif",
		static: "/dek/img/entry/9.jpg",
		current: undefined,
		title: "Vereinbarkeit von Beruf, Familie & Privatleben",
		text: "#WorkLifeBalance"
	},
	{
		gif: "/dek/img/entry/10.gif",
		static: "/dek/img/entry/10.jpg",
		current: undefined,
		title: "Jobticket",
		text: "#DBakel #ZugFälltHeuteAus"
	},
	{
		gif: "/dek/img/entry/11.gif",
		static: "/dek/img/entry/11.jpg",
		current: undefined,
		title: "Mobiles Arbeiten und flexible Arbeitszeiten",
		text: "#StayAtHome #HomeSweetHome #JogginghoseAllDay"
	},
	{
		gif: "/dek/img/entry/12.gif",
		static: "/dek/img/entry/12.jpg",
		current: undefined,
		title: "Gesundheitsbudget und Angebote",
		text: "#StayHealthy #Summerbody"
	},
])



const hcOpportunities = new HighlightCylinder()
engine.scene.add(hcOpportunities)
hcOpportunities.scale.set(0.7, 1, 0.7)
hcOpportunities.position.set(11.982, 1.5617, -22.04)

space.pciSystem.add({
	collider: new SphereCollider(hcOpportunities, 2),
	onColliderEnter: () => {
		engine.player.getInputController<PlayerCharacter>().activeActions.jumping = false; // avoid jumping
		spaceStore.hint.title = "Arbeitgeberleistungen"
	},
	onColliderLeave: () => {
		engine.player.getInputController<PlayerCharacter>().activeActions.jumping = true; // avoid jumping
		spaceStore.hint.title = undefined
	},
	onInteract: () => {
		if (!gameStore.quests[3].done) {
			gameStore.quests[3].done = true
			gameStore.newEnergycellFound = true
			space.eventbus.emit("newAnalytics", "energycellLiveQuiz")
		}
		spaceStore.showBenefitsModal = true
		spaceStore.hint.title = undefined
	}
})


function close() {
	showBenefitsModal.value = undefined
}


</script>

<style lang="scss" scoped>
@import "assets/css/variables";


.content {
	height: auto;
	width: 100%;
	@extend .flex-center;
	flex-direction: column;

	h1 {
		margin-bottom: 12px;

		svg {
			width: 100px;
			height: auto;
			margin: 0 3px !important;
			transform: scale(1);

			::v-deep(*) {
				fill: $red;
			}
		}
	}

	.wrapper {
		@extend .flex-center;
		flex-wrap: wrap;
		margin-top: 30px;

		img {
			width: auto;
			height: 100px;
			margin: 5px;
			opacity: 0.6;
			transition: ease 0.4s;
			position: relative;

			&:hover {
				opacity: 1;
				transform: scale(1.2);
				z-index: 3;
			}
		}
	}

	.description {
		@extend .flex-center;
		flex-direction: column;
		text-align: center;

		* {
			text-align: center;
		}
	}
}
</style>
