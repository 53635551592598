<template>
	<SpaceModal :show=showQuestModal @closed="showQuestModal = false">
		<img src="/dek/img/bot/young.png" class="bot1">
		<img src="/dek/img/bot/old.png" class="bot2">
		<img src="/dek/img/bot/energy.png" class="cell">

		<div class="content">

			<h1>Quest</h1>

			<template v-if="gameStore.questStage < 3">
				<p>Finde alle Energiezellen und schalte das Special frei.</p>
				<!-- <h2>Finde Energiezellen und nimm am Gewinnspiel teil.</h2>
				<p>Jede Energiezelle ist ein Los.<br>Bitte beachte, dass eine Teilnahme nur ab 18 Jahren möglich ist.</p> -->
			</template>
			<template v-else>
				<h2>Du hast alle Energiezellen gefunden!</h2>
				<div class="btn--dark" style="margin-top: 20px;" @click="pizza()">Werde zur Pizza</div>
				<p style="margin-top: 12px;">(Für 60 Sekunden - Du kannst es so oft wiederholen, wie du möchtest.)</p>
			</template>

			<!-- <div class="btn--dark" v-if="gameStore.canEnterRaffle" @click="spaceStore.showRaffleModal = true; showQuestModal = false;" style="margin-top: 20px;">Am Gewinnspiel teilnehmen</div> -->
			<!-- <div class="hint" v-if="!gameStore.canEnterRaffle" style="margin-top: 20px;">Du hast dich bereits fürs Gewinnspiel angemeldet. Wenn du in der Zwischenzeit noch weitere Energiezellen gesammelt hast, kannst du sie hier abgeben.</div> -->


			<div class="wrap">
				<div v-if="gameStore.newEnergycellFound" class="new">Bringe gesammelte Energiezellen zu Tally</div>
				<div class="energyProgress progress-bar">
					<svg width="126" height="213" viewBox="0 0 126 213" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M114.421 70.2644H80.675L96.7481 14.2371C96.7491 14.2336 96.7502 14.2301 96.7512 14.2266C97.4681 11.7591 97.0512 9.05506 95.5723 6.93345L95.5723 6.93345L95.5671 6.92603C94.0745 4.79661 91.6473 3.45869 88.9791 3.50096H38.5565C34.8377 3.45188 31.7754 6.02497 30.7852 9.4432L30.7822 9.45353L30.7793 9.46388L3.8338 104.666L3.83293 104.67C3.13425 107.147 3.5421 109.853 4.99597 111.999C6.46 114.161 8.86367 115.554 11.5385 115.59L11.5614 115.59H11.5843H39.4991L27.4116 198.832L27.4098 198.844L27.4081 198.857C26.9009 202.538 28.7763 206.34 32.3267 207.865C35.9621 209.428 40.0003 208.013 42.0863 204.798C42.0872 204.796 42.0882 204.795 42.0892 204.793L121.101 83.4343C122.759 80.8903 122.951 77.6152 121.635 74.8902L121.635 74.8893C120.308 72.1438 117.573 70.2575 114.421 70.2645V70.2644Z" fill="url(#paint0_linear_58_25)" stroke="#191D4A" stroke-width="7" />
						<defs>
							<linearGradient id="paint0_linear_58_25" x1="63" y1="7" x2="63" y2="205" gradientUnits="userSpaceOnUse">
								<stop stop-color="#85FC25" />
								<stop offset="0.489583" stop-color="#BEFE83" />
								<stop offset="1" stop-color="#86FF1D" />
							</linearGradient>
						</defs>
					</svg>

					<div class="progress" :style="{ width: (gameStore.questsFinished / gameStore.quests.length * 100) + '%' }"></div>
				</div>

				<div class="quest" v-for="(quest, index) in gameStore.quests" :class="quest.done ? 'done' : ''">
					<span>{{ index + 1 }}</span>
					<p>{{ quest.name }}</p>
				</div>

			</div>



		</div>

		<!--
		<div class="content" v-if="gameStore.questStage == 2">
			<h1>Super!</h1>
			<h2>Bringe alle Energiezellen zum Dekabot um die Quest abzuschließen.</h2>
		</div> -->


	</SpaceModal>
</template>

<script setup lang="ts">
import { isAnyTextInputActive } from '@ravespaceio/rave-engine/build/engine/src/utils/browser';
import { storeToRefs } from 'pinia';
import { canUseActionKey } from '~/space/_lib/utils/frontend';
import { getSpace } from '~/space/space';
import { useSpaceStore } from '~/store/space';
import { useGameStore } from '~/store/game';
import { changeAvatarTemporarily } from '~/space/_lib/mechanic/multiplayer';

const space = getSpace()
const spaceStore = useSpaceStore()
const gameStore = useGameStore()
const { showQuestModal } = storeToRefs(spaceStore)




space.eventbinder.bindDocument("keyup", (e) => {
	if (isAnyTextInputActive() || !spaceStore.onboardingDone) return;
	if ((e.key == "q" || e.key == "Q") && canUseActionKey()) {
		showQuestModal.value = !showQuestModal.value
	}
});


function pizza() {
	changeAvatarTemporarily({ model: space.project.publicPath + "/avatars/capi.glb", gender: "masculine", scale: 1 }, 60000) // i added the preview property to get rid of error not sure if is correct
	showQuestModal.value = false
}


</script>

<style scoped lang="scss">
@import "assets/css/variables";

.energyProgress {
	height: 30px;
	border-radius: 10px;
	margin-bottom: 20px;
	background: linear-gradient(90deg, #45ADC3 -146.4%, #191D4A 125.45%);
	border: 1px solid #4DB1C7;
	padding: 4px;
	width: 100%;

	svg {
		position: absolute;
		width: 11px;
		height: auto;
		right: 5px;
		top: 5px;
		z-index: 2;
	}

	.progress {
		position: relative;
		border: none;
		background: linear-gradient(180deg, #85FC25 0%, #BEFE83 48.96%, #86FF1D 100%);
	}
}

.new {
	text-align: center;
	width: 100%;
	margin-bottom: 20px;
	background: linear-gradient(180deg, #85FC25 0%, #BEFE83 48.96%, #86FF1D 100%);
	color: #191D4A;
	font-weight: 600;
	padding: 11px 10px;
	font-size: 14px;
	border-radius: $radius;
}

.box {
	@extend .flex-center;
	flex-direction: column;

	.bot1,
	.bot2 {
		position: absolute;
		bottom: 0;
		width: 300px;
		height: auto;
		z-index: 3;
	}

	.bot1 {
		left: -100px;
	}

	.bot2 {
		right: -150px;
		width: 400px;
	}

	.cell {
		position: absolute;
		right: 120px;
		bottom: 20px;
		width: 100px;
		transform: rotate(-25deg);
		z-index: 0;
	}

	.content {
		@extend .flex-center;
		padding-bottom: 20px;
		flex-direction: column;
		width: 100%;
		text-align: center;



		h1 {
			font-weight: 600;
		}

		h2 {
			font-weight: 600;
			font-size: 18px;
			margin-bottom: 6px;
		}

		img {
			margin-top: 12px;
			width: 100px;
			height: auto;
		}

		.wrap {
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
			flex-direction: column;
			margin-top: 50px;
			border: 1px solid #191D4A;
			border-radius: $radius;
			padding: 30px;

			.quest {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				margin-bottom: 12px;

				span {
					width: 25px;
					height: 25px;
					border-radius: 3px;
					background: linear-gradient(90deg, #45ADC3 -146.4%, #191D4A 125.45%);
					color: white;
					@extend .flex-center;
					margin-right: 8px;
					font-size: 12px;
					font-weight: 700;
					transform: translateY(-1px);
				}

				p {
					font-weight: 500;
					text-align: left;
				}

				&.done {
					span {
						background: linear-gradient(180deg, #85FC25 0%, #BEFE83 48.96%, #86FF1D 100%);
						color: #191D4A;
					}

					p {
						opacity: 0.4;
					}
				}
			}
		}
	}
}

@media(max-width:900px) {
	.box {

		.bot1,
		.cell,
		.bot2 {
			display: none;
		}
	}

	.wrap {
		padding: 15px !important;

		.new {
			font-size: 11px;
		}

		.quest {
			p {
				font-size: 11px;
			}
		}
	}
}
</style>
