import { getLsItem, setupReactiveLocalStorage } from "~/space/_lib/utils/storeHelper";
import * as THREE from "three"
import { Role } from "@ravespaceio/rave-engine/build/engineserver-common/src/SharedDataTypes/PlayerTypes";

let botYOffset = .3

export const dekaBotPositions = {
	start: new THREE.Vector3(21.4, 6.6 + botYOffset, -11.6),
	masterclass: new THREE.Vector3(-11.4, 10.6 + botYOffset, -6.4),
	blockchain: new THREE.Vector3(31.2, 5.2 + botYOffset, -0.55),
	oldBot: new THREE.Vector3(17.4, 2 + botYOffset, 10.4),
	hr: new THREE.Vector3(3, 2 + botYOffset, -26),
	platformQuiz: new THREE.Vector3(-5, 8.6 + botYOffset, -21),
	aufzug: new THREE.Vector3(-10.6, 1 + botYOffset, 1.6),
	stage: new THREE.Vector3(4.2, 1 + botYOffset, 16.6),
}

export const useGameStore = defineStore({
	id: "game",
	state: () => ({
		userRole: Role.default,
		// player current zone
		navZone: "",
		// Quest
		introState: 0,
		questStage: 0, // 0:Not Started, 1:Interacted With Bot, 2:Found all, 3:Finished at bot
		questsFinished: 0, // Count how many energycells found
		newEnergycellFound: false, // If a new energycell has been found it will indicate that you can enter a new one to the bot and the HC appears
		dekaBotLives: false, // If at least 1 energycell has entered, it will be saved and the bot will be active next time
		animateInventar: false, // Adds animation to avatar
		canEnterRaffle: false, // user can enter raffle once
		dekaBotText: "",
		introCam: undefined,
		dekabotGroup: undefined,
		dekaBotPosition: dekaBotPositions.start,
		quests: [
			{
				name: "Finde eine Energiezelle im Space",
				done: false
			},
			{
				name: "Löse das Plattform-Quiz",
				done: false
			},
			{
				name: "Schau dir ein Video im Cinema an",
				done: false
			},
			{
				name: "Entdecke unsere Arbeitgeberleistungen",
				done: false
			},
			{
				name: "Finde deine Abteilung beim Skillmatching",
				done: false
			},
			{
				name: "Gewinne am MasterMiner",
				done: false
			},
			{
				name: "Zieh eine Energiezelle aus dem Denergy-Automaten",
				done: false
			}
		],
		platformQuizState: 0,

		/* Live Quiz */
		liveQuizStarted: false,
		liveQuizRightAnswers: 0,

		/* Blockchain */
		blockchainGameState: 0,
		blockchainGameScore: 0

	}),
	actions: {
		init() {
			setupReactiveLocalStorage(this, "quests")
			setupReactiveLocalStorage(this, "questStage")
			setupReactiveLocalStorage(this, "platformQuizState")
			setupReactiveLocalStorage(this, "newEnergycellFound")
			setupReactiveLocalStorage(this, "dekaBotLives")
			setupReactiveLocalStorage(this, "canEnterRaffle")
			this.questStage = parseInt('' + this.questStage)
		}
	},
	getters: {
		canEnterStage(): boolean {
			return this.userRole === Role.superAdmin
		}
	},

})
