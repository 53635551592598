<template>
	<div id="inventar" class="ui ui-top" v-show="gameStore.questsFinished > 0 || gameStore.questStage > 0" @click="spaceStore.showQuestModal = true" :class="gameStore.animateInventar ? 'animate' : ''">
		<div class="box">
			<p>Energiezellen</p>
			<div class="wrap" :class="'progress-' + gameStore.questsFinished">
				<svg v-for="energy in gameStore.quests.length" width="9" height="17" viewBox="0 0 9 17" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M-5.18437e-08 15.814C-2.31221e-08 16.471 0.50175 17 1.125 17L7.875 17C8.49825 17 9 16.471 9 15.814L9 2.37209C9 1.71502 8.49825 1.18605 7.875 1.18605L6.375 1.18605L6.375 0.790697C6.375 0.352651 6.0405 -2.64039e-07 5.625 -2.45877e-07L3.375 -1.47526e-07C2.9595 -1.29364e-07 2.625 0.352651 2.625 0.790697L2.625 1.18605L1.125 1.18605C0.501749 1.18605 -6.68128e-07 1.71502 -6.39406e-07 2.37209L-5.18437e-08 15.814ZM1.69336 8.5054C1.69336 8.4361 1.75275 8.38294 1.81785 8.39267L4.05396 8.72547L4.05396 8.00195C4.05396 7.91458 4.1433 7.85927 4.21582 7.90157L7.25024 9.67833C7.35506 9.74025 7.30115 9.90868 7.18213 9.89144L4.94605 9.55941L4.94605 10.2829C4.94605 10.3703 4.8567 10.4256 4.78418 10.3833L1.74976 8.60656C1.71488 8.5862 1.69336 8.54743 1.69336 8.5054Z" fill="white" />
				</svg>
			</div>
			<div class="info" v-if="!MyEnv.$isMobile">
				<div class="key">Q</div>
				<p>Quest infos</p>
			</div>
			<div class="info" v-else>
				<div class="key" style="width: 100%;margin: 0;">Quest infos</div>
			</div>
			<div class="info quiz" v-if="gameStore.liveQuizStarted">
				<h4>Live Quiz</h4>
				<p><span class="key">{{ gameStore.liveQuizRightAnswers }}</span> richtig</p>
			</div>
		</div>
	</div>
	<img id="energycell" src="/dek/videos/energy.gif" v-show="showEnergy">
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { getSpace } from '~/space/space'
import { useGameStore } from '~/store/game';
import { useSpaceStore } from '~/store/space';
import { gsap, TweenMax, TweenLite, TimelineLite, Power3, Power2, Power1, Circ, Linear } from 'gsap'

const gameStore = useGameStore()
const spaceStore = useSpaceStore()
const showEnergy = ref(false)

watch(() => gameStore.quests, (quests) => {
	let q = 0
	for (let index = 0; index < quests.length; index++) {
		const quest = quests[index];
		if (quest.done) { q++ }
	}
	gameStore.questsFinished = q
	if (gameStore.questsFinished == gameStore.quests.length && gameStore.questStage != 3) {
		gameStore.questStage = 2
		spaceStore.showQuestModal = true
	}
}, { immediate: true, deep: true })


/* SHOW ENERGY ANIMATION */
watch(() => gameStore.questsFinished, (state) => {
	if (state) {
		showEnergy.value = true


		const tl = new TimelineLite({
			onComplete: () => {
				gameStore.animateInventar = true
				showEnergy.value = false
				document.getElementById("energycell").removeAttribute('style');
				document.getElementById("energycell").style.display = "none";
				setTimeout(() => {
					gameStore.animateInventar = false
					spaceStore.showQuestModal = true
				}, 4000);
			}
		})
		tl.to("#energycell", 0.8, { "opacity": 1, "scale": 1, ease: Power3.easeInOut });
		tl.to("#energycell", 2, { "top": -50, "right": -50, "scale": 0.5, ease: Power3.easeInOut });
		tl.to("#energycell", 0.5, { "scale": 0, "opacity": 0, ease: Power3.easeInOut }, "-=0.5");




	}
})

</script>

<style scoped lang="scss">
@import "assets/css/variables";

#energycell {
	position: fixed;
	width: 300px;
	height: auto;
	z-index: 999999999;
	right: calc(50% - 150px);
	top: calc(50% - 100px);
	opacity: 0;
	transform: scale(0);
}


#inventar {
	top: 10px;
	right: 10px;
	z-index: 9;
	position: fixed;
	display: flex;
	justify-content: flex-start;
	align-items: center;

	.box {
		padding: 8px 0;

		p {
			color: white;
			font-size: 13px;
			text-align: center;
			font-weight: 600;
		}

		.wrap {
			margin: 12px;
			@extend .flex-center;

			svg {
				width: 12px;
				height: auto;
				margin: 0 4px;
				opacity: 0.3;
			}

			&.progress-1 {
				svg:nth-child(1) {
					opacity: 1;
				}
			}

			&.progress-2 {
				svg:nth-child(-n+2) {
					opacity: 1;
				}
			}

			&.progress-3 {
				svg:nth-child(-n+3) {
					opacity: 1;
				}
			}

			&.progress-4 {
				svg:nth-child(-n+4) {
					opacity: 1;
				}
			}

			&.progress-5 {
				svg:nth-child(-n+5) {
					opacity: 1;
				}
			}

			&.progress-6 {
				svg:nth-child(-n+6) {
					opacity: 1;
				}
			}

			&.progress-7 {
				svg:nth-child(-n+7) {
					opacity: 1;
				}
			}
		}

		.info {
			@extend .flex-center;
			padding: 6px 13px;
			border-top: 1px solid $lineColor;

			.key {
				margin-right: 6px;
			}
		}

		.quiz {
			flex-direction: column;

			h4 {
				margin-bottom: 8px;
				text-align: center;
				color: $main;
				font-weight: 700;
			}

			p {
				@extend .flex-center;

				span {
					background: transparent;
					border: 1px solid $main;
					color: $main;
				}
			}
		}
	}
}

#inventar.animate {
	animation: pulse 0.5s ease 0s infinite normal forwards;

	.box {
		background: linear-gradient(180deg, #85FC25 0%, #BEFE83 48.96%, #86FF1D 100%);

		svg {
			* {
				fill: $dark;
			}
		}

		.key {
			background: $dark;
			color: white;
		}

		*,
		h4 {
			color: $dark;
		}
	}
}



@media(max-width:900px) {
	#inventar {
		.box {
			.wrap>svg {
				width: 9px;
				margin: 0 2px;
			}
		}
	}

}
</style>
