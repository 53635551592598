<template>
	<SpaceTrackingCookieHint />
	<div v-if="!spaceStore.onboardingDone" id="onboarding">




		<div id="onboarding-background" :style="'background-image:url(/dek/img/loader.jpg)'" />

		<IconLoader id="onboarding-loader" v-if="!showLoaderUi" />
		<div class="grid" v-if="showLoaderUi">
			<div class="left">
				<img src="/dek/img/logo.png" class="logo">
				<h2>Deka Career Day 2023</h2>
				<p>Entdecke bei unserem Deka Career Day im Metaverse spannende Wege, um deine berufliche Reise zu starten und in die Finanzwelt der Deka, der Sparkassen und der Finanz Informatik einzutauchen. Besuche die Vorträge unserer Speakerinnen und Speaker und lass dich von spannenden Zukunftsthemen inspirieren, von Tipps für deine Karriere über Blockchain bis hin zu KI und Nachhaltigkeit - alles in unserem neuen virtuellen Space.</p>
				<div class="progress-bar" id="loader--progress" v-if="loadingProgress < 1">
					<div class="progress" :style="{ width: (loadingProgress * 100) + '%' }" />
				</div>
				<div :class="canContinue ? '' : 'disabled'" v-if="!hasMultiplayer">
					<div id="continue" class="btn" @click="continueStuff()">Weiter</div>
				</div>
			</div>

			<div class="right" v-if="!closeExperience || auth">
				<SpaceOnboardingUserSettings v-if="hasMultiplayer" id="settings" class="settings" />

				<SpaceOnboardingControls v-if="!MyEnv.$isMobile" />
				<div :class="canContinue ? '' : 'disabled'" v-if="hasMultiplayer">
					<div id="continue" class="btn" @click="continueStuff()">Weiter</div>
				</div>
			</div>
			<div class="right" v-else>
				<div class="code">
					<h1>Zugangscode</h1>
					<input type="text" v-model="code" maxlength="4" placeholder="0000" id="meeting-input">
					<div class="btn" @click="checkCode()" :class="code.length < 4 ? 'disabled' : ''">Bestätigen</div>
				</div>
			</div>

		</div>

		<div class="imprint-links">
			<a href="https://careerday.deka.de/pdfs/Datenschutzhinweise_Deka_Career_Day.pdf" target="_blank">Datenschutzhinweis</a>
			<a href="https://www.deka.de/privatkunden/impressum" target="_blank">Impressum</a>
			<p @click="showCookieHint()">Cookie Einstellungen</p>
		</div>
	</div>
</template>

<script setup lang="ts">
import { gsap, TweenMax, TweenLite, TimelineLite, Power3, Power2, Power1, Circ, Linear, random } from 'gsap'
import { getSpace } from '~/space/space'
import { useSpaceStore } from '~/store/space'
import { storeToRefs } from 'pinia'
import { validateText } from '~/assets/js/text'


const space = getSpace()
const spaceStore = useSpaceStore()

const auth = ref(false)
const code = ref("")
function checkCode() {
	if (code.value.toLowerCase() == 'dk25') {
		auth.value = true;
	} else {
		alert("Falscher Code");
	}
}

const skipOnboarding = space.browser.urlParameter.get("skipOnboarding") || true
const fastOnboarding = space.browser.urlParameter.get("fastOnboarding")

const loadingProgress = ref(0)
const loaded = ref(false)
const { playerName } = storeToRefs(spaceStore)
const canContinue = computed(() => validateText(playerName.value) && loaded.value)

const hasMultiplayer = true
const showLoaderUi = ref(true)

const closeExperience = ref(false)
const closeExperienceText = ref("Das Event startet um 16:00 Uhr")

space.eventbus.onAlready("closeExperience", (data: any) => {
	closeExperience.value = data
})
space.eventbus.onAlready("closeExperienceText", (data: any) => {
	if (data) {
		closeExperienceText.value = data
	}
})

if (!space.ENV.IS_PROD && skipOnboarding) {
	nextTick(() => {
		spaceStore.onboardingDone = true;
		spaceStore.tutorialDone = true;
	})
}
else {
	onMounted(() => {
		space.loader.onAlready("loadingprogress", (prog) => {
			loadingProgress.value = prog;
		});
		space.loader.onceAlready("loaded", () => {
			loaded.value = true;
			if (fastOnboarding) continueStuff()
		});

		const tl = new TimelineLite({ repeat: -1 });
		tl.to("#onboarding-background", 20, { scale: 1.2 });
		tl.to("#onboarding-background", 20, { scale: 1 });
	})
}

// finished, fade out stuff
function continueStuff() {
	if (canContinue.value) {
		// const tl = new TimelineLite({});
		// tl.to("#onboarding", 0.6, { opacity: 0 });
		showLoaderUi.value = false
		space.screenManager.replayAllVideos()
		setTimeout(() => {
			spaceStore.onboardingDone = true;
			spaceStore.tutorialDone = true
		}, 50);
	}
};


function showCookieHint() {
	space.eventbus.emit("showCookieHint")
}

</script>

<style scoped lang="scss">
@import "assets/css/variables";

.imprint-links {
	position: fixed;
	bottom: 10px;
	width: 100%;
	left: 0;
	@extend .flex-center;
	z-index: 20;

	a,
	p {
		color: white;
		font-size: 12px;
		margin: 0 6px;
		text-decoration: underline;

		&:hover {
			cursor: pointer;
			opacity: 1;
		}
	}
}

#onboarding {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: $dark;
	z-index: 999999999;

	#onboarding-background {
		pointer-events: none;
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-position: center;
		background-size: cover;
		z-index: 0;
		opacity: 0.5;
		filter: blur(6px);
	}

	.grid {
		width: 100%;
		max-width: calc(100% - 200px);
		margin: 0 auto;
		height: 100%;
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		padding-bottom: 100px;
		position: relative;
		z-index: 2;

		.left {
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
			flex-direction: column;
			width: 500px;

			h2 {
				color: white;
				text-align: left;
				font-weight: 600;
				max-width: 100%;
				margin-bottom: 12px;
			}

			p {
				color: white;
				text-align: left;
				font-size: 14px;
				max-width: 100%;
			}

			.progress-bar {
				margin: 20px 0 0 0;
				border: none;
				background: #ffffff24;
				height: 6px;
				width: 100%;
				max-width: 400px;

				.progress {
					border: none;
				}
			}

			.logo {
				width: 300px;
				height: auto;
				margin-bottom: 22px;
			}
		}
	}

}

#continue {
	min-width: 200px;
	width: 100%;
	height: 55px;
	margin-top: 12px;
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;

	&:hover {
		background: rgba(8, 8, 8, 0.3);
	}
}

#onboarding-loader {
	position: fixed;
	z-index: 99;
	width: 50px;
	left: calc(50% - 25px);
	top: calc(50% - 25px);
}

.block {
	background: white;
	padding: 20px;
	margin-top: 20px;
	border-radius: $radius;
	text-align: center;
	max-width: 500px;
}


.code {
	@extend .flex-center;
	flex-direction: column;
	margin-top: 40px;
	background: #15171a;
	padding: 40px 70px;
	max-width: 100%;
	width: auto;
	border-radius: 20px;

	h1 {
		color: white;
		font-size: 25px;
		margin-bottom: 12px;
	}

	input {
		font-size: 24px;
		width: 180px;
		padding: 10px;
		padding-left: 28px;
		font-weight: 700;
		letter-spacing: 11px;
		border-radius: 8px;
		text-align: center;
		outline: none;
		text-transform: uppercase;
		border: none;
	}

	.btn {
		margin-top: 12px;
	}
}

@media(max-width:1000px) {
	#onboarding {
		.grid {
			max-width: calc(100% - 100px);

			.left {
				width: 450px;
			}
		}
	}
}

@media(max-height:840px) {
	#onboarding {
		.grid {
			max-width: calc(100% - 80px);
			padding-bottom: 40px;
		}
	}
}

@media(max-width:900px) {
	#onboarding {
		height: 100%;
		position: relative;
		overflow-y: auto;

		.imprint-links {
			position: relative;
			flex-wrap: wrap;

			a,
			p {
				margin: 0px;
				font-size: 11px;
			}
		}

		.grid {
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			max-width: calc(100% - 50px);
			min-height: 100%;
			height: auto;
			padding-bottom: 60px;
			padding-top: 50px;
			position: relative;

			.left {
				width: 100%;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				.logo {
					width: 140px;
				}

				p {
					text-align: center;
				}

				.progress-bar {
					max-width: 300px;
				}
			}

			.right {
				width: 100%;
				max-width: 400px;

				#continue {
					height: 50px;
				}
			}
		}
	}
}
</style>
