import Engine, { CloudPlayerAgoraHandler, RavespaceEvent, VideoScreenF } from "@ravespaceio/rave-engine";
import { Logging, LoggingArea } from "@ravespaceio/rave-engine/build/engineserver-common/src/utils/Logging";
import { Mesh, MeshBasicMaterial, PositionalAudio, VideoTexture } from "three";
// import { replaceMaterial } from "./utils/utils";
import { replaceMaterialInScene } from "@ravespaceio/rave-engine/build/engine/src/utils/materials";
import { StageType } from "~/space/space";
import ScreenManager from "./ScreenManager";

export default class CloudPlayerManager {

	public videoChannelName: string;

	public videoTracks = new Map<number, any>();
	public agoraAudioTracks = new Map<number, any>();
	public mediaStreams = new Map<number, MediaStream>();
	public positionalAudios = new Map<number, THREE.PositionalAudio>();
	public screenManager: ScreenManager;
	public onlyVideoScreens: number[] = [];
	private _publishedScreensIds: number[] = [];

	constructor(private _engine: Engine, screenManager: ScreenManager, cloudPlayerHandler: CloudPlayerAgoraHandler, options: { prefix: string, stage: StageType, onlyVideoScreens?: number[] }) {

		this.videoChannelName = options.prefix + options.stage.toUpperCase();
		this.screenManager = screenManager;
		this.onlyVideoScreens = options.onlyVideoScreens || [];
		// https://laenqnmtxddjuzm6.s3.eu-central-1.amazonaws.com/soufian_total_e89a41804d.mp4

		Logging.info(`SETUP CloudPlayerManager, channelName: ${this.videoChannelName}, screenIds: ${this.screenManager.allowedUids}`, LoggingArea.Agora);

		cloudPlayerHandler.onLeaveAgoraChannel.on(() => {
			this._publishedScreensIds.forEach((uid) => {
				this.screenManager.replaceTexturebyId(undefined, uid);
			});

		});

		cloudPlayerHandler.getVideobyCp_Publish = (user) => {
			if (cloudPlayerHandler.controller.agoraOptions.channel != this.videoChannelName) return;
			Logging.trace("startVideo " + user.uid + " " + cloudPlayerHandler.controller.agoraOptions.channel, LoggingArea.Agora);
			// video not playing on first interact but on setupPromise it is
			this._engine.audioManager.setupPromise.then(() => {
				// fix using mediastream instead of agora play method
				const mediaStream = getVideoTracktoMediaStream(user);
				this._publishedScreensIds.push(user.uid as number);
				this.screenManager.replaceTexturebyId(mediaStream, user.uid as number);
			})
		}

		cloudPlayerHandler.disposeVideobyCp_Unpublish = (user) => {
			if (cloudPlayerHandler.controller.agoraOptions.channel != this.videoChannelName) return;
			Logging.trace("stopVideo " + user.uid + " " + cloudPlayerHandler.controller.agoraOptions.channel, LoggingArea.Agora);
			this._publishedScreensIds = this._publishedScreensIds.filter(id => id !== user.uid);
			this.screenManager.replaceTexturebyId(undefined, user.uid as number);
		}

		cloudPlayerHandler.getAudiobyCp_Publish = (user) => {
			if (cloudPlayerHandler.controller.agoraOptions.channel != this.videoChannelName) return;
			Logging.trace("startAudio " + user.uid + " " + cloudPlayerHandler.controller.agoraOptions.channel, LoggingArea.Agora);

			if (this.onlyVideoScreens.includes(user.uid as number)) {
				Logging.trace("screen " + user.uid + " is set to video only. " + cloudPlayerHandler.controller.agoraOptions.channel, LoggingArea.Agora);
				return;
			};

			const screen = this.screenManager.videoScreens.get(user.uid as number);

			if (!screen || Array.isArray(screen)) return;

			Logging.trace(user.uid, LoggingArea.Agora)

			this._engine.audioManager.setupPromise.then(() => {
				const mediaTrack = user.audioTrack.getMediaStreamTrack()
				const mediaStream = new MediaStream();
				mediaStream.addTrack(mediaTrack);
				this.agoraAudioTracks.set(user.uid as number, user.audioTrack);
				this.mediaStreams.set(user.uid as number, mediaStream);
				this.addPosAudio(user.uid as number, mediaStream, screen);
			})

		}

		cloudPlayerHandler.disposeAudiobyCp_Unpublish = (user) => {

			if (this.onlyVideoScreens.includes(user.uid as number)) {
				Logging.trace(`Abort audio despose. Screen ${user.uid} was set to video only`, LoggingArea.Agora);
				return;
			};

			Logging.trace('stopAudio', LoggingArea.Agora);

			this.agoraAudioTracks.delete(user.uid as number);
			this.mediaStreams.delete(user.uid as number);
			this.positionalAudios.delete(user.uid as number);
		}

		// ? is this correct??
		cloudPlayerHandler.onLeaveAgoraChannel.on((data) => {
			if (data?.channel === '') return;
			this.screenManager.cleanAllScreensOnLeaving()
		})

		// setupDebugContainer(_engine)
	}

	public addPosAudio(uid: number, mediaStream: MediaStream, screen: Mesh) {
		const audio = new PositionalAudio(this._engine.audioManager.listener!);
		audio.setRefDistance(5);
		audio.setDirectionalCone(150, 180, 1);
		audio.setVolume(1.5)
		audio.setMaxDistance(20)
		audio.setMediaStreamSource(mediaStream);
		audio.autoplay = false;
		audio.hasPlaybackControl = true;
		screen.add(audio);
		audio.play();
		this.positionalAudios.set(uid, audio);

		return audio
	}

	public checkTracks() {
		setInterval(() => {
			if (this.agoraAudioTracks.size > 1) {
				this.agoraAudioTracks.forEach((agoraTrack) => {
					Logging.info(`Agora audio track isPlaying: ${agoraTrack.isPlaying}`, LoggingArea.Agora)

				})
			}
		}, 1000)

		setInterval(() => {
			if (Array.from(this.mediaStreams.keys()).length < 1) {
				Logging.info('No tracks', LoggingArea.Agora)
			} else {
				this.mediaStreams.forEach((mediaStream) => {
					mediaStream.getTracks().forEach((track) => {
						Logging.info(`mediaStream: state ${track.readyState}`, LoggingArea.Agora)
					}
					)
				})
			}
		}, 1000)
	}

}

function getVideoTracktoMediaStream(user: any): MediaStream {
	const mediaTrack = user.videoTrack.getMediaStreamTrack()
	const mediaStream = new MediaStream()
	mediaStream.addTrack(mediaTrack)
	return mediaStream
}
