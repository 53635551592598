import Engine, { EngineUtils, Logging, LoggingArea, RPMAvatar, VertexWindDisplacer } from "@ravespaceio/rave-engine"
import * as THREE from "three"
import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { setupNavigation } from "../_lib/mechanic/navigation"
import { setupMaterials } from "./materials"
import AnimationExtension, { AESequences } from "../_lib/gltfExtensions/AnimationExtension"
import ArtworkExtension from "../_lib/gltfExtensions/ArtworkExtension"
import InstancedMeshExtension from "../_lib/gltfExtensions/InstancedMeshExtension"
import InteractionExtension from "../_lib/gltfExtensions/InteractionExtension"
import LightExtension from "../_lib/gltfExtensions/LightExtension"
import LightFlickerAnimation from "@ravespaceio/rave-engine/build/engine/src/helper/three/FlickerAnimatedLight"
import { getSpace } from "../space"
import { getEngine } from "../engine"
import { GuiThreeHelper } from "@ravespaceio/rave-engine/build/engine/src/helper/gui/GuiThreeHelper"
import { findMeshs, testName } from "@ravespaceio/rave-engine/build/engine/src/utils/findings"
import { setupPlatformMinigame } from "./game/minigamesetup"
import { setupVideoScreens } from "./setupScreens"



export default function setupScene() {

	const space = getSpace()
	const engine = getEngine()

	space.loader.addFiles({
		"navmesh": `${space.project.publicPath}/models/navigation.glb`,
	})
	const roomFiles = {
		"main": `${space.project.publicPath}/models/main/main.glb`,
		"areas": `${space.project.publicPath}/models/areas/areas.glb`,
		"env": `${space.project.publicPath}/models/env/env.glb`,
		"env_props": `${space.project.publicPath}/models/env_props/env_props.glb`,


	}
	space.loader.addFiles(roomFiles)


	const applyMaterials = setupMaterials()
	engine.physics.isEnable = false

	space.loader.on("loaded", async () => {
		Logging.info("Setting up models", LoggingArea.Space)

		const modelGroup = new THREE.Group()
		modelGroup.name = "ModelGroup"
		engine.scene.add(modelGroup)
		for (const [name, path] of Object.entries(roomFiles)) {
			const model = (space.loader.getLoaded(name) as GLTF).scene
			modelGroup.add(model)
		}
		EngineUtils.Three.makeStatic(modelGroup)
		EngineUtils.Three.makeFrontSide(modelGroup)
		EngineUtils.Three.makeShadowEnabled(modelGroup, false, false)

		// empties
		const navmeshGltf = space.loader.getLoaded("navmesh")
		setupNavigation(navmeshGltf.scene)

		setupVideoScreens(modelGroup);
		space.screenManager.spaceReadyCallback()

		new InteractionExtension(modelGroup, { chair: "outside" })

		// LightID
		new LightExtension(modelGroup, {
			blockchain: () => new THREE.PointLight(0xff4000, 5, 30, 1),
			masterclass: () => new THREE.PointLight(0x004cff, 3, 20, 1),
			hr: () => new THREE.PointLight(0xff7e38, 3, 20, 1),
			stage: () => new THREE.PointLight(0xff7e38, 10, 20, 1)

		})

		// AnimationID
		new AnimationExtension(modelGroup, {
			levitate_big: (obj) => AESequences.translationPreset({ axis: "Y", offset: AESequences.seedRndm() * 5, amplitude: 1, speed: .2 }, obj),
			rotateLocalY: (obj) => AESequences.rotationPreset({ axis: "Y", offset: AESequences.seedRndm(), space: "local", speed: 1 }, obj),
			rotateGlobalY: (obj) => AESequences.rotationPreset({ axis: "Y", offset: AESequences.seedRndm(), space: "world", speed: 1 }, obj)
		})

		// ObjectID
		const ime = new InstancedMeshExtension(modelGroup)
		await ime.isLoaded

		applyMaterials(modelGroup)


		space.browser.isVR.then(isVR => {
			if (isVR) {

			} else {
				setupPlatformMinigame(modelGroup);
			}
		})

		addCollidersToDebugGUI(engine);
		engine.physics.isEnable = true
	})
}
